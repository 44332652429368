import { FC } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { certificationsByPersonDetailsRoute } from 'route';
import { ByPerson } from 'ByPerson/model/ByPerson';
import { StatusChip } from 'components/StatusChip';
import { BoldTypography } from 'components/Page';
import { NameTableCellWrapper } from './ByPersonTable.style';

export const columns: DataTableColumn<ByPerson>[] = [
  {
    field: 'displayName',
    headerName: 'Name',
    renderField: ({
      displayName,
      userName
    }) => (
      <NameTableCellWrapper>
        <BoldTypography variant="body2">{`${displayName} (${userName})`}</BoldTypography>
      </NameTableCellWrapper>
    ),
  },
  {
    field: 'enrollCount',  
    headerName: 'Certificates',
    notSortable: true,
    renderField: ({
      enrollCount,
      completedCount,
      expiredCount,
      expiredNoRenewalCount,
      inProcessCount,
    }) => (
      <NameTableCellWrapper>
        <div>
          {!!enrollCount && (
            <StatusChip label={`${enrollCount} Requests`} color="grey" />
          )}
          {!!completedCount && (
            <StatusChip label={`${completedCount} Completed`} color="green" />
          )}
          {!!expiredCount && <StatusChip label={`${expiredCount} Expiring/Expired`} color="red" />}
          {!!expiredNoRenewalCount && <StatusChip label={`${expiredNoRenewalCount} Expiring/Expired (No Renewal)`} color="orange" />}
          {!!inProcessCount && (
            <StatusChip label={`${inProcessCount} In process`} color="blue" />
          )}
        </div>
      </NameTableCellWrapper>
    ),
  },
  {
    field: 'trainingSetsInfo',
    headerName: 'Training Sets',
    notSortable: true,
    renderField: ({
      trainingSetsInfo
    }) => (
      <NameTableCellWrapper>
        <div>
          {!!trainingSetsInfo.completed && (
            <StatusChip label={`${trainingSetsInfo.completed} Completed`} color="green" />
          )}
          {!!trainingSetsInfo.inProcess && (
            <StatusChip label={`${trainingSetsInfo.inProcess} In process`} color="blue" />
          )}
          {(!!trainingSetsInfo.inProcess || !!trainingSetsInfo.completed)&& (
            <StatusChip label={`${trainingSetsInfo.percentageCompleted} % Completed`} color="grey" />
          )}

        </div> 
      </NameTableCellWrapper>
    ),
  },
];
const getDetailsRoute = (userId: number): string =>
  certificationsByPersonDetailsRoute.createRoute({ userId: String(userId) });

export interface ByPersonTableProps
  extends Omit<DataTableProps<ByPerson>, 'columns'> {}

export const ByPersonTable: FC<ByPersonTableProps> = (props) => {
  const handleOpenDetails = (userId: number) => () =>
    window.open(
      `${window.location.origin}${getDetailsRoute(userId)}`,
      '_blank'
    );

  return (
    <DataTable
      {...props}
      columns={columns}
      rowSelector={(row) => row.id}
      renderRowActions={(row) => (
        <IconButton onClick={handleOpenDetails(row.id)}>
          <NavigateNextIcon />
        </IconButton>
      )}
      noDataMessage="No data to display"
      hideCollapseColumn
    />
  );
};
